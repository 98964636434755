/* eslint-disable jsx-a11y/alt-text */

"use client";

import Image from "next/image";
import { defaultImage } from "@/utils/utils";

const ImageCom = (props, ref) => {
  return (
    <Image
      {...props}
      placeholder={defaultImage}
      onError={(e) => {
        e.target.src = defaultImage;
      }}
    ></Image>
  );
};

export default ImageCom;
