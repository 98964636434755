"use client";
import React, { useRef, useEffect, useState } from "react";
// import { Toast } from "antd-mobile";

import Link from "next/link";

const CusLink = (props) => {
  // const ToastRef = useRef();
  // const [isQQUC, setIsQQUC] = useState();

  // useEffect(() => {
  //   const ua = window.navigator.userAgent;
  //   if (
  //     ua.includes("iPhone") &&
  //     (ua.includes("QQBrowser") || ua.includes("UCBrowser"))
  //   ) {
  //     setIsQQUC(true);
  //   }
  //   // return () => {
  //   //   Toast.clear();
  //   //   ToastRef.current?.clear?.();
  //   // };
  // }, []);

  // if (isQQUC) return <a {...props}></a>;

  return (
    <a
      {...props}
      onClick={(e) => {
        // if (
        //   !props.href.includes("app") &&
        //   !props.href.includes("iframe") &&
        //   !props.href.includes("bangmai") &&
        //   !props.href.includes("http")
        // ) {
        //   ToastRef.current = Toast.show({
        //     icon: "loading",
        //     duration: 3000
        //   });
        // }

        if (props.onClick) props.onClick(e);
      }}
    >
      {props.children}
    </a>
  );
};

export default CusLink;
